import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserDataService } from "../services/user-data/user-data.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor{

    constructor(private userService: UserDataService){}
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headers = new HttpHeaders().append('Authorization', `Bearer ${this.userService.getUserId}`);
        const modifiedReq = req.clone({headers});
        return next.handle(modifiedReq);
    }
}