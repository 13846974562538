import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, IonRouterOutlet, NavParams } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicStorageModule } from '@ionic/storage';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { UserDataService } from './services/user-data/user-data.service';
import { Network } from '@ionic-native/network/ngx';
import { ModalFormService } from './services/modal-form/modal-form.service';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';
import { VideoEditor } from '@ionic-native/video-editor/ngx';
import { environment } from 'src/environments/environment';
//  firebase imports, remove what you don't require
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { ChatService } from './services/chat/chat.service';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';
import { FilePath } from '@awesome-cordova-plugins/file-path/ngx';
import { SMS } from '@ionic-native/sms/ngx';
import { PDFGenerator } from '@awesome-cordova-plugins/pdf-generator/ngx';
import { YearSelectComponent } from './components/year-select/year-select.component';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from "./interceptors/index";

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  exports:[HttpClientModule],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    ReactiveFormsModule,
    IonicStorageModule.forRoot({
      name: "db"
    }),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule
  ],
  providers: [
    StatusBar, HTTP, FormBuilder, SQLite, IOSFilePicker, Camera, WebView, FilePath, SMS,
    SplashScreen, IonicStorageModule, UserDataService, Network, ModalFormService, PDFGenerator,
    Chooser, FileTransfer, VideoEditor, NavParams, ChatService, PreviewAnyFile,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
export const firebaseConfig = {
  apiKey: '',
  authDomain: '',
  databaseURL: '',
  storageBucket: '',
  messagingSenderId: ''
};