import { Injectable } from '@angular/core';
import { HTTP, HTTPResponse } from '@awesome-cordova-plugins/http/ngx';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { AlertController, LoadingController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { FileTransfer, FileTransferObject, FileUploadOptions, FileUploadResult } from '@ionic-native/file-transfer/ngx';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  api_url: string = environment.apiUrl;
  public native: boolean = false;
  private entete: {} = {};
  public device:any;
  private loader: HTMLIonLoadingElement;
  private id_ecole: any;
  
  constructor(private http: HTTP, private httpClient: HttpClient, 
    private platform: Platform, private storage: Storage, 
    public loadingCtrl: LoadingController, private transfer: FileTransfer, private alertCtrl: AlertController) { 
      this.createLder();
      this.device = this.platform.platforms();
      console.log({platform: this.device});
      this.getEcole();
      // if(this.device.indexOf("mobileweb"))
      
    // this.http.setDataSerializer("json");
    
    // this.getEntetes();
  }

  async createLder(){
    this.loader = await this.loadingCtrl.create({
      message:"Chargement..."
    });
  }
  getEntetes(): void
  {
    this.storage.get("access_token").then((data) => {
      if(data)
      {
        this.entete = {
         authorization: "Bearer "+data,
        };
        console.log(this.entete);
        
      }
    });
  }

  getEcole(): Promise<number>{
    return this.storage.get("id_ecole").then(id=>{
      this.id_ecole = id;
      console.info({id})
      return id;
    });
  }

  // public uploadPic(uri, method="", reference): Promise<any>
  // {
  //   console.log({reference: method});
    
    // let fileTransferObject: FileTransferObject = this.fileTransfer.create();
    // return fileTransferObject.upload(uri, method, {
    //   headers: this.entete,
    //   fileKey:"imageFilename",
    //   chunkedMode:false,
    //   params: {
    //     reference:reference,
    //   }
    // });
  // }

  public getData(method="", data: any, headers: {} = this.entete): Observable<HTTPResponse | JSON>
  { 
    data.device = this.device;
    data.ecole = this.id_ecole;
    let back: Observable<any>;
    if(!this.native)
    {
      back = this.httpClient.get(this.api_url+method, {
        headers: headers,
        params: data
      });
    }
    else
    {
      let backThen = this.http.get(this.api_url+method, data, headers);
      back = from(backThen).pipe()
    }
    return back;
  }
  public setTestServer()
  {
    this.api_url = "https://natidja.octra.io/";
  }

  public postData(method, params, loader: boolean = true, entetes: {} = this.entete): Promise<any>
  {
    // method = "/createClient/"
    // this.setTestServer()

    let url =  this.api_url;

    // if(loader)
    this.presentLoader();
    params.device = this.device;

    if(this.id_ecole != null && (typeof(params.ecole) == undefined || params.ecole == null))
      params.ecole = this.id_ecole;

    if(this.native)
    {
      let backThen = this.http.post(url+method, params, entetes);
      backThen.finally(()=>{
        this.dissmissLoader()
      })
      return (backThen);
    }
    else
    {
      let backThen = this.httpClient.post(url+method, params, entetes).toPromise();
      backThen.finally(()=>{
        this.dissmissLoader()
      })
      return backThen;
    }
  }
  public postOffLine(method, params, entetes: {} = this.entete): Observable<any>
  { 
    // this.setTestServer()
    params.device = this.device;
    if(!params.action) return;
    let e = {method:method, params: params, entetes: entetes};
    let array: Array<any> = [];

    
    if(this.native)
    {
      // debugger
      let backThen: Promise<any> = this.http.post(this.api_url+method, params, entetes);
      backThen.then(d => {
        let json = JSON.parse(d.data);
        if(json.status == "ok"){
          array.splice(array.indexOf(e));
          this.storage.set("postData", array);
        }else{
          console.log(json.msg);
        }
      }, (error)=>{
        this.storage.get("postData").then(d=>{
          if(d){
            array = d;
          }
          if(array.some(i => JSON.stringify(i) == JSON.stringify(e))){
            console.log(array, e);
            
            console.log("already in");
          }else
          {
            array.push(e);
            this.storage.set("postData", array);
          }
        })
      })
      return from(backThen);
    }
    else
    {
      return this.httpClient.post(this.api_url+method, params, {});
    }
  }


  startUpload(blob: Blob, params: any): Observable<any> {
    const formData = new FormData();

    formData.append("file", blob);
    formData.append("ecole", this.id_ecole)
    for(const p in params){
      formData.append(p, params[p]);
    }

    return this.uploadData(formData);
  }

  // Upload the formData to our API
  uploadData(formData: FormData): Observable<any> {
    let loader;
    const loading = this.loadingCtrl.create({
        message: 'Envoie de l\'image...',
    }).then(p => {loader = p; p.present()});

    // Use your own API!
    const url = this.api_url;

    return this.httpClient.post(`${environment.apiUrl}action_mobile_gestion.php`, formData).pipe(
        finalize(() => {
            loader.dismiss();
        })
    );
  }

  public async upload(file: any, fileName: any, params: any) : Promise<FileUploadResult>{

    params.ecole = this.id_ecole;
    let options: FileUploadOptions = {
      fileKey:"file",
      fileName: fileName,
      params:params,
      headers:{}
    };
    console.log({upload_files: file});
    console.log({upload_files: options});
    const fileTransfer: FileTransferObject = this.transfer.create();
    const result = await fileTransfer.upload(file ?? "", this.api_url+"action_mobile_gestion.php", options);
    return Promise.resolve(result);
  }

  putData(method, params, entetes: {} = this.entete): Observable<any> {
    
    params.device = this.device;
    
    if(this.native)
    {
      let backThen = this.http.put(this.api_url+method, params, entetes);
      return from(backThen);
    }
    else
    {
      return this.httpClient.put(this.api_url+method, params, {
        responseType: 'text',
        headers:entetes
      });
    }
  }
  deleteData(method, entetes: {} = this.entete) {
    if(this.native)
    {
      let backThen = this.http.delete(this.api_url+method,{}, entetes);
      return from(backThen);
    }
    else
    {
      return this.httpClient.delete(this.api_url+method, {
        responseType: 'text',
        headers:entetes
      });
    }
  }
  presentLoader(){
    // await this.createLder();
    this.loader?.present();
  }
  dissmissLoader(){
    this.loader?.dismiss();
  }
  public alert(msg: string, header: string = null, subHeader: string = null, buttons: any[] = ["ok"]){
    this.alertCtrl.create({header:header, subHeader: subHeader, message:msg, buttons:buttons}).then(a => {
      a.present();
    })
  }
}