import { Component, OnInit, ViewChild } from '@angular/core';

import { IonRouterOutlet, NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { UserDataService } from './services/user-data/user-data.service';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { EventsService } from './services/events/events.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public ecole: {nom:string, logo:string} = {nom:"Ecole", logo:""};
  public logo_ecole: string = "";
  public id_enseignant: any;
  public loggedIn = false;
  public permissions: any;
  private db: SQLiteObject;

  @ViewChild(IonRouterOutlet, { static : true }) routerOutlet: IonRouterOutlet;

  public appPages = [
    // {
    //   title: 'Trash',
    //   url: '/folder/Trash',
    //   icon: 'trash'
    // },
    // {
    //   title: 'Spam',
    //   url: '/folder/Spam',
    //   icon: 'warning'
    // }
  ];

  logout(){
    this.userData.logout();
  }
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar, private storage: Storage, 
    private userData: UserDataService, private navCtrl: NavController, 
    private sqlite: SQLite, private events: EventsService
  ) {
    this.initializeApp();
  }

  addPageToMenu = (p: {title:string, url:string, icon:string}) => {
    console.log("adding "+p.title )
    if(!this.appPages.some((value) => value.url == p.url)){
      this.appPages.push(p)
    }
  }

  private initAccount(){
    this.userData.hasLoggedIn().then(l => this.loggedIn = l);
    this.storage.get("permissions").then(p => {
      if(p){
        this.permissions = p;
        console.log({permissions: this.permissions});
        
        this.storage.get("id_enseignant").then(e => {
          this.id_enseignant = e;
          if(this.permissions.droit_notes != "" || this.permissions.droit_presences != "" || this.permissions.droit_bilans != "" || this.permissions.droit_coatching != ""){
            this.addPageToMenu({title: 'Accueil',url: '',icon: 'home'})
            this.navCtrl.navigateRoot("");
          }
          else if(this.permissions.droit_ecolages != "")
          {
            this.navCtrl.navigateRoot("caisse");
          }
          if(this.permissions.droit_notes != ""){
            this.addPageToMenu({title: 'Notes & Livrets',url: 'notes',icon: 'checkmark-circle'})
            this.addPageToMenu({title: 'Statistiques',url: 'stats',icon: 'pie-chart'})
          }
          if(this.permissions.droit_presences != ""){
            this.addPageToMenu({title: 'Vie Scolaire',url: 'vie-scolaire',icon: 'heart'})
          }
          if(this.permissions.droit_ecolages != ""){
            this.addPageToMenu({title: 'Finance', url: 'caisse', icon: 'wallet'})
          }
          if(this.permissions.droit_eleves != ""){
            this.addPageToMenu({title: "Gestion des élèves", url:"list-eleves", icon:"happy"});
          }
          if(this.permissions.droit_classes != ""){
            this.addPageToMenu({title: "Gestion des classes", url:"classes", icon:"business"});
          }
          if(this.permissions.droit_ecolages != ""){
            this.addPageToMenu({title: "Documents", url:"documents", icon:"document"});
          }
          // if(this.permissions.droit_employes != ""){
          //   this.addPageToMenu({title: 'Gestion des enseignants', url: 'enseignants', icon: 'person'});
          // }

          this.addPageToMenu({title: 'Mon profil',url: 'profil',icon: 'person'})
          this.addPageToMenu({title: 'Articles',url: 'fourniture-list',icon: 'book'})
          this.addPageToMenu({title: 'Fournitures',url: 'classe-fourniture',icon: 'book'})
          console.debug(this.permissions);
        })
      }
    });
    this.storage.get("nom_ecole").then((n)=>{
      this.ecole.nom = n;
    })

    
    this.storage.get("logo_ecole").then(l => {
      this.ecole.logo = l;
      console.log(this.ecole);
    })
  }

  initializeApp() {
    this.platform.ready().then(() => {

      this.platform.backButton.subscribeWithPriority(-1, ()=>{

        if(!this.routerOutlet.canGoBack()){
          navigator['app'].exitApp();
        }
      })

      this.initAccount();

      this.statusBar.styleDefault();
      this.splashScreen.hide();
      

      let request = `create table IF NOT EXISTS etudiant(id number, nom VARCHAR(32), prenom VARCHAR(32), 
      matricule VARCHAR(32), id_classe VARCHAR(32), nom_classe VARCHAR(32), photo VARCHAR(32), id_person number, UNIQUE(id))`;

      this.sqlite.create({
        name: 'nana.db',
        location: 'default'
      })
      ?.then((db: SQLiteObject) => {
        this.db = db;
        db.executeSql(request, [])
          .then(() => console.log('Executed SQL'))
          .catch(e => console.error(e));
      })
      ?.catch(e => console.error(e));

      this.events.getObservable().subscribe((data) => {
        console.log("Data received:", data);
        this.initAccount();
      })
    });
  }

  ngOnInit() {
    
  }
}
